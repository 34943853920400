import { FunctionComponent, useCallback, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import SummaryPill from './SummaryPill'
import { TAnswerData, TLinkData } from '@utils/chatbot/types'
import useClickOutside from '@hooks/useClickOutside'
import Blink from '@components/Blink'
import useViewportType from '@hooks/useViewport/useViewportType'
import useTracking from '@hooks/useTracking'
import {
  trackClickHandler,
  TrackingClickType,
} from '@widgets/SectionSummary/utils'

interface SummaryListProps {
  clickHandler: (index: number | undefined) => void
  summaries: TAnswerData[]
  links?: TLinkData
  trackingClickType: TrackingClickType
  inline?: boolean
}

const SummaryListWrapper = styled.ul<{ inlined?: boolean }>`
  ${({
    inlined = false,
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing12, spacing16 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopy },
        },
      },
    },
  }) => css`
    ${!inlined
      ? css`
          list-style-type: disc;
          margin-left: ${spacing16};
          padding-left: ${spacing16};
        `
      : css`
          margin-bottom: ${spacing16};
        `};
    margin-top: 0;

    > span,
    > li {
      margin: 0;
      max-width: 632px;
      padding-bottom: ${spacing12};
      ${small2BodyCopy}

      &:last-child {
        padding-bottom: 0;
      }
    }

    li::marker {
      color: ${blickRedColor};
      font-size: 20px;
      line-height: 8px;
    }
  `}
`

const BoldItem = styled.b`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: medium1Subhead },
        },
      },
    },
  }) => css`
    ${medium1Subhead}
  `}
`
const ListElement = styled.li``

const SummaryPillWrapperLink = styled(Blink)`
  ${({
    theme: {
      color: {
        secondary: { darkRed },
      },
    },
  }) => css`
    position: relative;
    top: -3px;
    cursor: pointer;

    &:hover {
      div > span {
        background-color: ${darkRed};
      }
    }
  `}
`

const SummaryPillWrapperButton = styled.button`
  ${({
    theme: {
      color: {
        secondary: { darkRed },
      },
    },
  }) => css`
    position: relative;
    top: -3px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    &:hover {
      div > span {
        background-color: ${darkRed};
      }
    }
  `}
`

const SummaryList: FunctionComponent<SummaryListProps> = ({
  summaries,
  links,
  clickHandler,
  trackingClickType,
  inline,
}) => {
  const viewportType = useViewportType()
  const isMobile = viewportType !== 'desktop'
  const SummaryPillWrapper = useMemo(
    () =>
      isMobile
        ? (SummaryPillWrapperButton as typeof SummaryPillWrapperLink)
        : SummaryPillWrapperLink,
    [isMobile]
  )
  const pillRef = useRef<HTMLAnchorElement>(null)
  const trackClick = useTracking(trackClickHandler)
  useClickOutside({
    elementRef: pillRef,
    onClickOutside: () => clickHandler(undefined),
  })

  const removeLastDot = (text: string) => {
    if (text.endsWith('.')) {
      return text.slice(0, -1)
    }
    return text
  }

  const scrollToClickHandler = useCallback(
    (item: string) => {
      const activeIndex = parseInt(item)
      if (isNaN(activeIndex) || activeIndex === null || !isMobile) {
        clickHandler(undefined)
      } else {
        clickHandler(activeIndex - 1)
      }
    },
    [clickHandler, isMobile]
  )

  const ref = useRef<HTMLUListElement>(null)

  return (
    <SummaryListWrapper ref={ref} as={inline ? 'div' : 'ul'} inlined={inline}>
      {summaries.map((summary, index) => {
        const tooltipItem = links && links[summary.link]
        const url = tooltipItem?.url
          ? new URL(tooltipItem.url)
          : { pathname: '' }
        return (
          <ListElement
            as={inline ? 'span' : 'li'}
            key={`summary-list-item-${summary.link}-${index}`}>
            {inline && ' '}
            {summary.category && <BoldItem>{summary.category}:&nbsp;</BoldItem>}
            {removeLastDot(summary.text)}&nbsp;
            {summary.link && (
              <SummaryPillWrapper
                ref={pillRef}
                href={url.pathname}
                aria-label="Zur selektierten Zusammenfassung springen"
                onClick={() => {
                  if (event?.target instanceof HTMLSpanElement) {
                    trackClick({
                      event: trackingClickType,
                      label: 'pill',
                      linkUrl: url.pathname,
                    })
                  }
                  scrollToClickHandler(summary.link)
                }}>
                <SummaryPill
                  trackingClickType={trackingClickType}
                  popoverParentRef={ref}
                  tooltipItem={tooltipItem}>
                  {summary.link}
                </SummaryPill>
              </SummaryPillWrapper>
            )}
            {'.'}
          </ListElement>
        )
      })}
    </SummaryListWrapper>
  )
}

export default SummaryList
