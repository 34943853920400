import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const Skeleton = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${spacing16};
  `}
`

const SkeletonGrid = styled.div<{ direction?: 'row' | 'column' }>`
  ${({
    theme: {
      spacing: { spacing8 },
    },
    direction = 'column',
  }) => css`
    display: flex;
    flex-direction: ${direction};
    align-items: flex-start;
    width: 100%;
    gap: ${spacing8};
  `}
`

export const SkeletonLine = styled.div<{ width?: string }>`
  ${({
    theme: {
      color: {
        tertiary: { grey200, grey300 },
      },
    },
    width = '100%',
  }) => css`
    min-width: 16px;
    width: ${width};
    height: 16px;
    border-radius: 16px;
    animation: pulse 1s infinite;

    @keyframes pulse {
      0% {
        background-color: ${grey200};
      }
      50% {
        background-color: ${grey300};
      }
      100% {
        background-color: ${grey200};
      }
    }
  `}
`

const SkeletonBullet = styled(SkeletonLine)`
  ${() => {
    return css`
      flex: 0 0 16px;
      border-radius: 50%;
    `
  }}
`

export enum SkeletonSeed {
  Paragraph = 'p1',
  ParagraphNatural = 'p2',
  BulletPoint = 'b',
}

type SkeletonSeedWithModifier = { type: SkeletonSeed; multiplier: number }

const isSkeletonSeedWithModifier = (
  seed: SkeletonSeed | SkeletonSeedWithModifier
): seed is SkeletonSeedWithModifier => {
  return Object.prototype.hasOwnProperty.call(seed, 'multiplier')
}

const generateSkeletonLines = (count: number = 1, uneven: boolean = false) =>
  Array(count)
    .fill(0)
    .map((_, index) => (
      <SkeletonLine
        key={`skeleton-line-${index}`}
        width={index % 2 === 0 && uneven ? '95%' : '100%'} // uneven lines make the skeleton look more natural
      />
    ))

export const SkeletonView: FunctionComponent<{
  seed: SkeletonSeed[] | SkeletonSeedWithModifier[]
}> = ({ seed }) => {
  return (
    <Skeleton>
      {seed.map((seedUnguarded, idx) => {
        let seed: SkeletonSeed
        let count = 4

        if (isSkeletonSeedWithModifier(seedUnguarded)) {
          seed = seedUnguarded.type
          count = seedUnguarded.multiplier
        } else {
          seed = seedUnguarded
        }

        if (seed === SkeletonSeed.Paragraph) {
          return (
            <SkeletonGrid key={`skeleton-grid-paragraph-${idx}`}>
              {generateSkeletonLines(count)}
            </SkeletonGrid>
          )
        } else if (seed === SkeletonSeed.ParagraphNatural) {
          return (
            <SkeletonGrid key={`skeleton-grid-paragraph-uneven-${idx}`}>
              {generateSkeletonLines(count, true)}
            </SkeletonGrid>
          )
        } else if (seed === SkeletonSeed.BulletPoint) {
          return (
            <SkeletonGrid
              direction="row"
              key={`skeleton-grid-bullet-point-${idx}`}>
              <SkeletonBullet />
              <SkeletonGrid>{generateSkeletonLines(count)}</SkeletonGrid>
            </SkeletonGrid>
          )
        }
      })}
    </Skeleton>
  )
}
